<template>
  <div class="storeArea">
    <el-card>

      <div slot="header" class="clearfix">
        <el-form :inline="true" class="demo-form-inline" size="mini">
          <el-form-item class="btn-right">
            <el-button @click="add({areaId:0,areaLevel:0})" size="mini" type="success">新增仓库</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="header" class="clearfix">
        <el-table
          :data="tableData"
          style="width: 100%;margin-bottom: 20px;"
          row-key="areaId"
          border
          :tree-props="{children: 'children'}">
          <el-table-column prop="areaId" label="区位编码"></el-table-column>
          <el-table-column prop="areaName" label="区位名称"></el-table-column>
          <el-table-column prop="areaLevel" label="等级"></el-table-column>
<!--          <el-table-column prop="sort" label="排序"></el-table-column>-->
          <el-table-column prop="mark" label="描述"></el-table-column>
          <el-table-column label="操作"  fixed="right">
            <template slot-scope="{row}">
              <el-button  size="mini" type="success" @click="() => add(row)"> 添加</el-button>
              <el-button @click="edit(row)" type="warning" size="mini" >修改</el-button>
              <el-button v-if="!row.children" @click="del(row)" type="danger" size="mini" >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-if="dialogVisible">
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="30%"
          @close="onCancel">
          <div >
            <div>
              <el-form :model="areaInfo" label-position="left" label-width="100px" size="small">
                <el-form-item label="区位名称：">
                  <el-input v-model="areaInfo.areaName"></el-input>
                </el-form-item>
                <el-form-item label="描述：">
                  <el-input v-model="areaInfo.mark"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div slot="footer">
              <el-button @click="onCancel">取 消</el-button>
              <el-button type="primary" @click="save">确 定</el-button>
            </div>
          </div>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {findAreaInfoListTree,saveAareInfo,getAreaInfoByAreaId,editAareInfo,delAreaInfoByAreaId} from "@/services/storeArea";

export default {
  name: 'storeArea',
  data() {
    return {
      // 用于存储资源列表数据
      tableData: [],
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      dialogType: '',
      dialogVisible: false,
      title: '',
      areaInfo:{},
      initLevel: 0,
    }
  },
  methods: {
    // 新增或修改确认按钮
    async save() {
      if (this.dialogType=='add') {
        const {data} = await saveAareInfo(this.areaInfo);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadAreaInfoListTree()
          this.areaInfo = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
      if (this.dialogType=='edit') {
        const {data} = await editAareInfo(this.areaInfo);
        if (200== data.code) {
          this.$message.success('操作成功')
          await this.loadAreaInfoListTree()
          this.areaInfo = {}
          this.dialogVisible = false
        }else {
          this.$message.error(data.message)
        }
      }
    },
    onCancel () {
      this.dialogVisible = false
      this.areaInfo = {}
    },

    // 新增
    add(data){
      this.dialogType = 'add'
      this.title = '新增'
      this.dialogVisible = true
      this.areaInfo.parentAid=data.areaId
      this.areaInfo.areaLevel=data.areaLevel*1 +1
    },
    // 修改
    async edit(row){
      this.dialogVisible=true
      this.title='编辑'
      this.dialogType='edit'
      const {data} = await getAreaInfoByAreaId(row.areaId)
      if (data.code ===200) {
        this.areaInfo = data.data
      } else {
        this.$message.error('获取区位信息失败')
      }
    },
    // 删除
    async del(row){
      try {
        await this.showConfirmationDialog(row);
        // 用户点击了确定按钮的逻辑
        await this.remove(row);
      } catch {
        this.$message.info('取消操作')
        // 用户点击了取消按钮的逻辑
        // 可以不做任何操作，或者执行取消操作
      }
    },
    // 删除调用后台接口
    async remove(row) {
      let areaId = row.areaId
      const {data} =await delAreaInfoByAreaId(areaId)
      if (200 === data.code) {
        this.$message.success('操作成功')
        await this.loadAreaInfoListTree()
      } else {
        this.$message.error('操作失败')
      }
    },
    // 删除提示弹框
    showConfirmationDialog(row) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否删除【${row.areaName}】？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          resolve(); // 用户点击了确定按钮，将Promise解析为成功状态
        }).catch(() => {
          reject(); // 用户点击了取消按钮，将Promise解析为失败状态
        });
      });
    },


    async loadAreaInfoListTree() {
      // 开始加载数据
      this.isLoading = true
      const {data} = await findAreaInfoListTree()
      if (200 === data.code) {
        this.tableData = data.data
      }
      this.isLoading = false
    },

  },
  created() {
    this.loadAreaInfoListTree()
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>

</style>
