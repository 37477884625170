import request from '@/utils/request'

// 查出所有商品分类以及子分类，树形结构
export function findAreaInfoListTree() {
  return request({
    method: 'post',
    url: `/auth/areaInfo/list/tree`,
  })
}

// 新增区位信息
export function saveAareInfo(data) {
  return request({
    method: 'post',
    url: `/auth/areaInfo/save`,
    data
  })
}


// 根据areaId获取区位信息
export function getAreaInfoByAreaId(areaId) {
  return request({
    method: 'post',
    url: `/auth/areaInfo/info/${areaId}`,
  })
}

// 修改区位信息
export function editAareInfo(data) {
  return request({
    method: 'post',
    url: `/auth/areaInfo/edit`,
    data
  })
}

// 根据areaId获取区位信息
export function delAreaInfoByAreaId(areaId) {
  return request({
    method: 'post',
    url: `/auth/areaInfo/delete/${areaId}`,
  })
}
